var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"font-black text-xl"},[_vm._v("Account detail")]),_c('c-btn',{staticClass:"ml-auto bg-blue-500",domProps:{"textContent":_vm._s(_vm.orderMode ? 'Cancel' : 'Make order')},on:{"click":function($event){_vm.orderMode = !_vm.orderMode}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderMode),expression:"orderMode"}],staticClass:"flex flex-col"},[_c('c-select',{attrs:{"label":"Side","options":[
        {
          key: _vm.SIDES.BUY,
          text: 'Buy',
        },
        {
          key: _vm.SIDES.SELL,
          text: 'Sell',
        } ]},model:{value:(_vm.side),callback:function ($$v) {_vm.side=$$v},expression:"side"}}),_c('c-input',{attrs:{"label":"Volume","type":"number"},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=_vm._n($$v)},expression:"volume"}}),_c('c-toggle-btn',{staticClass:"my-2",attrs:{"label":"Reduce only"},model:{value:(_vm.reduceOnly),callback:function ($$v) {_vm.reduceOnly=$$v},expression:"reduceOnly"}}),_c('c-btn',{staticClass:"mx-auto bg-green-500",on:{"click":_vm.postOrder}},[_vm._v("Order")])],1),_c('c-select',{attrs:{"label":"Exchange","options":[
      {
        key: 'BYBIT',
        text: 'Bybit',
      },
      {
        key: 'FTX',
        text: 'Ftx',
      } ],"disabled":_vm.orderMode},model:{value:(_vm.copyAcc.exchange),callback:function ($$v) {_vm.$set(_vm.copyAcc, "exchange", $$v)},expression:"copyAcc.exchange"}}),_c('c-input',{attrs:{"label":"Name","disabled":_vm.orderMode},model:{value:(_vm.copyAcc.name),callback:function ($$v) {_vm.$set(_vm.copyAcc, "name", $$v)},expression:"copyAcc.name"}}),(_vm.copyAcc.exchange === 'FTX')?_c('c-input',{attrs:{"disabled":_vm.orderMode,"label":"Sub Account"},model:{value:(_vm.copyAcc.subAccount),callback:function ($$v) {_vm.$set(_vm.copyAcc, "subAccount", $$v)},expression:"copyAcc.subAccount"}}):_vm._e(),_c('c-input',{attrs:{"label":"API Key","disabled":_vm.orderMode},model:{value:(_vm.copyAcc.key),callback:function ($$v) {_vm.$set(_vm.copyAcc, "key", $$v)},expression:"copyAcc.key"}}),_c('c-input',{attrs:{"label":"API Secret","disabled":_vm.orderMode},model:{value:(_vm.copyAcc.secret),callback:function ($$v) {_vm.$set(_vm.copyAcc, "secret", $$v)},expression:"copyAcc.secret"}}),_c('c-btn',{staticClass:"mt-2 mx-auto",attrs:{"disabled":_vm.orderMode},domProps:{"textContent":_vm._s('Save')},on:{"click":_vm.saveAccount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }