<template>
  <div
    class="bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"
  >
    <div class="font-black text-xl">Add account to group: {{ group.name }}</div>
    <c-select
      label="Exchange"
      :options="[
        {
          key: 'BYBIT',
          text: 'Bybit',
        },
        {
          key: 'FTX',
          text: 'Ftx',
        },
      ]"
      v-model="exchange"
    />
    <c-input label="Name" v-model="name"></c-input>
    <c-input
      v-if="exchange === 'FTX'"
      label="Sub Account"
      v-model="subAccount"
    />
    <c-input label="API Key" v-model="key"></c-input>
    <c-input label="API Secret" v-model="secret"></c-input>
    <c-btn class="mt-2 mx-auto" @click="createAccount">Create</c-btn>
  </div>
</template>

<script>
export default {
  name: 'AddAccount',
  props: {
    group: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      exchange: '',
      key: '',
      secret: '',
      name: '',
      subAccount: '',
    }
  },
  methods: {
    async createAccount() {
      if (this.exchange && this.key && this.secret) {
        const { success, error } = await this.$http.post(
          '/api/v1/markets/account',
          {
            exchange: this.exchange,
            key: this.key,
            secret: this.secret,
            groupId: this.group.id,
            name: this.name,
            subAccount: this.subAccount,
          }
        )
        this.$notify({
          type: success ? '' : 'error',
          group: 'notify',
          title: 'Account',
          text: success ? 'Success' : error,
        })
        this.$emit('oke', {})
      } else {
        this.$notify({
          type: 'warn',
          group: 'notify',
          title: 'Account',
          text: 'Please fill all fields.',
        })
      }
    },
  },
}
</script>
