<template>
  <div
    class="bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"
  >
    <div class="flex">
      <div class="font-black text-xl">Account detail</div>
      <c-btn
        class="ml-auto bg-blue-500"
        @click="orderMode = !orderMode"
        v-text="orderMode ? 'Cancel' : 'Make order'"
      />
    </div>

    <div v-show="orderMode" class="flex flex-col">
      <c-select
        label="Side"
        :options="[
          {
            key: SIDES.BUY,
            text: 'Buy',
          },
          {
            key: SIDES.SELL,
            text: 'Sell',
          },
        ]"
        v-model="side"
      />
      <c-input label="Volume" v-model.number="volume" type="number" />
      <c-toggle-btn label="Reduce only" v-model="reduceOnly" class="my-2" />
      <c-btn @click="postOrder" class="mx-auto bg-green-500">Order</c-btn>
    </div>

    <c-select
      label="Exchange"
      :options="[
        {
          key: 'BYBIT',
          text: 'Bybit',
        },
        {
          key: 'FTX',
          text: 'Ftx',
        },
      ]"
      :disabled="orderMode"
      v-model="copyAcc.exchange"
    />
    <c-input label="Name" :disabled="orderMode" v-model="copyAcc.name" />
    <c-input
      :disabled="orderMode"
      v-if="copyAcc.exchange === 'FTX'"
      label="Sub Account"
      v-model="copyAcc.subAccount"
    />
    <c-input label="API Key" v-model="copyAcc.key" :disabled="orderMode" />
    <c-input
      label="API Secret"
      v-model="copyAcc.secret"
      :disabled="orderMode"
    />
    <c-btn
      class="mt-2 mx-auto"
      @click="saveAccount"
      v-text="'Save'"
      :disabled="orderMode"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
const SIDES = {
  BUY: 1,
  SELL: 2,
}

export default {
  name: 'account-detail',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      copyAcc: null,
      orderMode: false,
      volume: 0,
      reduceOnly: false,
      side: 1,
      SIDES,
    }
  },
  created() {
    this.copyAcc = cloneDeep(this.account)
  },
  methods: {
    async saveAccount() {
      try {
        const payload = {
          id: this.account.id,
          groupId: this.account.groupId,
          name: this.copyAcc.name,
          key: this.copyAcc.key,
          secret: this.copyAcc.secret,
          exchange: this.copyAcc.exchange,
          subAccount: this.copyAcc.subAccount,
        }
        await this.$http.post('/api/v1/markets/account', payload)
        this.$notify({
          group: 'notify',
          title: 'Account',
          text: 'Success',
        })
        this.$emit('oke', {})
      } catch (e) {
        this.$notify({
          type: 'error',
          group: 'notify',
          title: 'Account',
          text: 'Err: ' + e,
        })
      }
    },
    async postOrder() {
      const payload = {
        accountId: this.account.id,
        reduceOnly: this.reduceOnly,
        volume: this.volume,
        side: this.side,
      }
      const { success } = await this.$http.post(
        '/api/v1/markets/execute',
        payload
      )
      if (success) {
        this.$notify({
          group: 'notify',
          title: 'Notification',
          text: 'Success',
        })
        this.volume = 0
        this.$emit('oke')
      } else {
        this.$notify({
          type: 'error',
          group: 'notify',
          title: 'Notification',
          text: 'Failed to create order',
        })
      }
    },
  },
}
</script>
