<template>
  <div>
    <div class="flex align-middle content-between">
      <div class="flex font-black align-middle items-center">
        Account Groups
      </div>
      <c-btn class="ml-2" @click="showAddGroup = !showAddGroup">
        Add group
      </c-btn>
    </div>

    <c-base-modal v-if="showAddGroup" @clickAway="showAddGroup = false">
      <add-group @oke="createSuccess" />
    </c-base-modal>

    <div class="flex flex-col" v-if="groups">
      <group
        v-for="(group, index) in groups"
        :key="index"
        :group="group"
        :price="price"
        @oke="createAccSuccess"
      />
    </div>
  </div>
</template>

<script>
import Group from './group-card'
import AddGroup from './add-group'

export default {
  name: 'Groups',
  components: {
    Group,
    AddGroup,
  },
  data: () => ({
    showAddGroup: false,
    groups: [],
    price: 0,
  }),
  async created() {
    await this.fetchGroups()
  },
  methods: {
    async createSuccess() {
      this.showAddGroup = false
      await this.fetchGroups()
    },
    async fetchGroups() {
      const { price } = await this.$http.get('/api/market/price')
      const groups = await this.$http.get('/api/v1/markets/group')
      const accounts = await this.$http.get('/api/v1/markets/account')

      this.price = price
      accounts.forEach((acc) => {
        if (acc.exchange === 'FTX') {
          acc.balance = (acc.balance || 0) / price
          acc.equity = (acc.equity || 0) / price
        }
      })

      for (const group of groups) {
        group.balance = 0
        group.equity = 0
        group.accounts = []
        for (const acc of accounts) {
          if (acc.groupId === group.id) {
            group.balance += acc.balance || 0
            group.equity += acc.equity || 0
            group.accounts.push(acc)
          }
        }
      }
      this.groups = groups
    },
    createAccSuccess() {
      this.fetchGroups()
    },
  },
}
</script>
