<template>
  <div
    class="bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"
  >
    <div class="flex flex-col">
      <div class="font-black text-xl">Confirm order</div>

      <div class="mt-4">
        <div class="flex flex-col">
          <div class="mt-2">
            <span class="font-bold text-gray-600">Side: </span>
            <span class="text-2xl">{{
              payload.side === 1 ? 'Buy' : 'Sell'
            }}</span>
          </div>

          <div class="mt-2">
            <span class="font-bold text-gray-600">Vol: </span>
            <span class="text-2xl">{{ payload.volume | formatNumber }}</span>
          </div>

          <div>
            <span class="font-bold text-gray-600">Reduce only: </span>
            <span class="text-2xl">{{
              payload.reduceOnly ? 'Yes' : 'No'
            }}</span>
          </div>
        </div>

        <div class="mt-4 flex justify-center">
          <c-btn
            class="bg-orange-400"
            @click="$emit('oke')"
            v-text="'Cancel'"
          />
          <c-btn
            class="bg-blue-500 ml-4"
            @click="makeOrder"
            v-text="'Make order'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirm-order',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async makeOrder() {
      const { success } = await this.$http.post(
        '/api/v1/markets/execute',
        this.payload
      )
      if (success) {
        this.$notify({
          group: 'notify',
          title: 'Notification',
          text: 'Success',
        })
        this.$emit('oke')
      } else {
        this.$notify({
          type: 'error',
          group: 'notify',
          title: 'Notification',
          text: 'Failed to create order',
        })
      }
    },
  },
}
</script>
