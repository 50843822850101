<template>
  <div
    class="bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"
  >
    <div class="font-black text-xl">Edit group</div>
    <c-input label="Name" v-model="name" />
    <c-btn class="mt-2 mx-auto" @click="editGroup">Save</c-btn>
  </div>
</template>

<script>
export default {
  name: 'EditGroup',
  props: {
    group: {
      required: true,
    },
  },
  data() {
    return {
      name: '',
    }
  },
  created() {
    this.name = this.group.name
  },
  methods: {
    async editGroup() {
      if (this.name) {
        await this.$http.post('/api/v1/markets/group', {
          name: this.name,
          id: this.group.id,
        })
        this.$notify({
          group: 'notify',
          title: 'Group',
          text: 'Success',
        })
        this.$emit('oke', {})
      } else {
        this.$notify({
          type: 'warn',
          group: 'notify',
          title: 'Group',
          text: 'Please set name.',
        })
      }
    },
  },
}
</script>
