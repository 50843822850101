<template>
  <div class="w-full border-2 rounded shadow-sm mx-1 my-2 p-1">
    <div
      @click="show = !show"
      class="flex w-full rounded-xl sm:p-2 md:p-4 sm:mr-2 sm:mt-2 cursor-pointer justify-items-stretch items-center"
    >
      <div class="flex flex-col w-1/4">
        <span class="font-black">Name: </span>
        <span>
          {{ group.name }}
        </span>
      </div>
      <div class="flex flex-col w-1/4 ml-2">
        <span class="font-black">Accounts: </span>
        <span>{{ group.accounts.length }}</span>
      </div>
      <div class="flex flex-col w-1/4">
        <span class="font-black">Balances: </span>
        <span>{{ group.balance | fix8 | formatNumber }}</span>
      </div>
      <div class="flex flex-col w-1/4">
        <span class="font-black">Equity: </span>
        <span>{{ group.equity | fix8 | formatNumber }}</span>
      </div>
      <c-icon :name="show ? 'chevron-up' : 'chevron-down'" />
    </div>
    <div v-if="show">
      <c-raw-table
        v-if="accounts"
        :headers="[
          'Name',
          'Exchange',
          'UID',
          'Side',
          'Volume',
          'Reduce Only',
          'Make order',
          'Balance',
          'Equity',
          'Position',
          'Entry Price',
          'Price',
          'Unrealised P/L',
          'Sub Account',
          'Edit',
          'Delete',
        ]"
        height="380px"
        :items="accounts"
        itemClass="cursor-pointer"
      >
        <template v-slot:header="{ headers }">
          <tr>
            <th
              v-for="(item, key) in headers"
              :key="key"
              class="raw-table-header"
            >
              {{ item }}
            </th>
          </tr>
        </template>

        <template v-slot:item="{ item }">
          <td class="raw-table-item font-bold">
            {{ item.name }}
          </td>
          <td class="raw-table-item font-bold">
            {{ item.exchange.toUpperCase() }}
          </td>
          <td class="raw-table-item cursor-pointer">
            <template v-if="item.status !== 1">
              <div class="flex-center flex-col text-red-500">
                <div class="flex-center">
                  <span>
                    {{ item.exchangeUserId }}
                  </span>
                  <c-icon
                    name="refresh"
                    class="ml-2 cursor-pointer"
                    :width="20"
                    :height="20"
                    @click="refresh(item.id)"
                  />
                </div>
                <div>Invalid API key</div>
              </div>
            </template>
            <span v-else>
              {{ item.exchangeUserId }}
            </span>
          </td>

          <td class="raw-table-item bg-gray-500">
            <c-toggle-btn
              v-model="item.order.isBuy"
              :label="item.order.isBuy ? 'Buy' : 'Sell'"
            />
          </td>

          <td class="raw-table-item bg-gray-500">
            <c-input v-model.number="item.order.vol" style="width: 75px" />
          </td>

          <td class="raw-table-item bg-gray-500">
            <c-toggle-btn
              v-model="item.order.reduceOnly"
              :label="item.order.reduceOnly ? 'On' : 'Off'"
            />
          </td>

          <td class="raw-table-item bg-gray-500">
            <c-btn @click="makeOrder(item)">Order</c-btn>
          </td>

          <td class="raw-table-item">
            {{ item.balance | fix8 | formatNumber }}
          </td>
          <td class="raw-table-item ml-auto">
            {{ item.equity | fix8 | formatNumber }}
          </td>
          <td class="raw-table-item">
            <span v-if="item.position > 0">Long: </span>
            <span v-if="item.position < 0">Short: </span>
            {{ Math.abs(item.position) | formatNumber }}
          </td>
          <td class="raw-table-item">
            {{ item.averagePrice | formatNumber }}
          </td>
          <td class="raw-table-item">
            {{ price | formatNumber }}
          </td>
          <td class="raw-table-item">
            <template v-if="item.pl">
              ₿{{ item.pl }}
              <span class="text-gray-700">
                (${{ (item.pl * price) | formatNumber }})
              </span>
            </template>
            <span v-else> - </span>
          </td>
          <td class="raw-table-item ml-auto">
            {{ item.subAccount || '-' }}
          </td>

          <td class="raw-table-item">
            <c-icon
              name="edit"
              class="cursor-pointer"
              @click="showDetailAccount(item.id)"
            />
          </td>

          <td class="raw-table-item">
            <c-icon
              name="circle-x"
              class="cursor-pointer"
              @click="deleteAccount(item.id)"
            />
          </td>
        </template>
      </c-raw-table>

      <div class="flex justify-between px-2">
        <c-btn class="my-4 flex items-center" @click="showAddAcc = true">
          Add account
          <c-icon name="plus" height="22" stroke-width="2" />
        </c-btn>

        <c-btn class="my-4 flex items-center" @click="showEdit">
          Edit
          <c-icon name="edit" height="22" stroke-width="2" />
        </c-btn>

        <c-btn class="my-4 flex items-center" @click="deleteGroup(group.id)">
          Delete
          <c-icon name="minus" height="22" stroke-width="2" />
        </c-btn>
      </div>
    </div>

    <c-base-modal v-if="showAddAcc" @clickAway="showAddAcc = false">
      <add-account :group="group" @oke="successAddAcc" />
    </c-base-modal>

    <c-base-modal v-if="showEditGroup" @clickAway="showEditGroup = false">
      <edit-group :group="group" @oke="successEditGroup" />
    </c-base-modal>

    <c-base-modal v-if="detailAcc" @clickAway="detailAcc = null">
      <account-detail :account="detailAcc" @oke="successDetailAcc" />
    </c-base-modal>

    <c-base-modal v-if="payloadOrder" @clickAway="resetConfirm">
      <confirm-order :payload="payloadOrder" @oke="resetConfirm" />
    </c-base-modal>
  </div>
</template>

<script>
import AddAccount from './add-account'
import EditGroup from './edit-group'
import AccountDetail from './account-detail'
import clonedeep from 'lodash.clonedeep'
import ConfirmOrder from '@/views/Accounts/confirm-order'

const BUY = 1,
  SELL = 2

export default {
  name: 'AccountGroup',
  components: { ConfirmOrder, AccountDetail, EditGroup, AddAccount },
  props: {
    group: {
      type: Object,
      require: true,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      showAddAcc: false,
      showEditGroup: false,
      editAcc: null,
      detailAcc: null,
      accounts: [],
      payloadOrder: null,
    }
  },
  created() {
    this.copyAccounts()
  },
  methods: {
    copyAccounts() {
      this.accounts = clonedeep(this.group.accounts).map((item) => {
        item.order = {
          isBuy: true,
          vol: 0,
          reduceOnly: true,
        }
        return item
      })
    },
    async makeOrder(item) {
      const { id, order } = item
      this.payloadOrder = {
        accountId: id,
        reduceOnly: order.reduceOnly,
        volume: order.vol,
        side: order.isBuy ? BUY : SELL,
      }
    },
    showDetailAccount(item) {
      this.detailAcc = item
    },
    async refresh(id) {
      await this.$http.post('/api/v1/markets/account/' + id + '/refresh')
      this.$router.go()
    },
    async deleteAccount(id) {
      await this.$http.delete('/api/v1/markets/account/' + id)
      this.$router.go()
    },
    async deleteGroup(id) {
      await this.$http.delete('/api/v1/markets/group/' + id)
      this.$router.go()
    },
    successAddAcc() {
      this.showAddAcc = false
      this.$emit('oke')
    },
    successEditGroup() {
      this.showEditGroup = false
      this.$emit('oke')
    },
    successDetailAcc() {
      this.detailAcc = null
      this.$emit('oke')
    },
    showEdit(event) {
      this.showEditGroup = true
      event.stopPropagation()
    },
    showEditAcc(acc) {
      this.editAcc = acc
    },
    resetConfirm() {
      this.payloadOrder = null
      this.copyAccounts()
    },
  },
}
</script>
