var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow-lg z-50 px-6 py-4 flex flex-col w-5/6 md:w-2/4"},[_c('div',{staticClass:"font-black text-xl"},[_vm._v("Add account to group: "+_vm._s(_vm.group.name))]),_c('c-select',{attrs:{"label":"Exchange","options":[
      {
        key: 'BYBIT',
        text: 'Bybit',
      },
      {
        key: 'FTX',
        text: 'Ftx',
      } ]},model:{value:(_vm.exchange),callback:function ($$v) {_vm.exchange=$$v},expression:"exchange"}}),_c('c-input',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.exchange === 'FTX')?_c('c-input',{attrs:{"label":"Sub Account"},model:{value:(_vm.subAccount),callback:function ($$v) {_vm.subAccount=$$v},expression:"subAccount"}}):_vm._e(),_c('c-input',{attrs:{"label":"API Key"},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}}),_c('c-input',{attrs:{"label":"API Secret"},model:{value:(_vm.secret),callback:function ($$v) {_vm.secret=$$v},expression:"secret"}}),_c('c-btn',{staticClass:"mt-2 mx-auto",on:{"click":_vm.createAccount}},[_vm._v("Create")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }